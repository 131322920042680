<!--
 * @Author: DY
 * @Date: 2021-01-16 16:04:28
 * @LastEditTime: 2022-09-02 17:20:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\views\purchaseManage\procurementContractSchedule\index.vue
-->
<template>
    <div class="procurementContractSchedule" v-loading="loading">
        <div class="page_header">
            <div class="fonm_list">
                <h4>供应商：</h4>
                <el-input v-model="queryCriteria.supplier_name" placeholder="请输入内容"></el-input>
            </div>
            <div class="fonm_list">
                <h4>合同名称：</h4>
                <el-input v-model="queryCriteria.contract_name" placeholder="请输入内容"></el-input>
            </div>
            <div class="fonm_list">
                <h4>场站：</h4>
                <el-select v-model="queryCriteria.station_id" placeholder="请选择">
                    <el-option
                        v-for="item in stationData"
                        :key="item.station_id"
                        :label="item.name"
                        :value="item.station_id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="fonm_list">
                <h4>时间：</h4>
                <el-date-picker
                    v-model="queryCriteria.timeSlot"
                    type="daterange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </div>
            <div class="fonm_list">
                <el-button type="primary" @click="queryData">
                    查询
                </el-button>
            </div>
        </div>
        <div class="currencyTable">
            <div class="el-table">
                <div class="table_header">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:2rem;">
                                    供应商
                                </th>
                                <th style="width:2rem;">
                                    合同名称
                                </th>
                                <th style="width:1.1rem;">
                                    场站
                                </th>
                                <th style="width:1.1rem;">
                                    原材料名称
                                </th>
                                <th style="width:1.1rem;">
                                    原材料子类
                                </th>
                                <th style="width:1.1rem;">
                                    原材料规格
                                </th>
                                <th style="width:1.1rem;">
                                    数量
                                </th>
                                <th style="width:1.1rem;">
                                    场站均价
                                </th>
                                <th>
                                    金额
                                </th>
                                <th style="width:9px;"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table_content">
                    <table>
                        <tbody>
                            <tr class="total">
                                <td style="width:2rem;">
                                    合计
                                </td>
                                <td style="width:2rem;">
                                    {{ tableTotalData.contract_amount }}
                                </td>
                                <td style="width:1.1rem;"></td>
                                <td style="width:1.1rem;"></td>
                                <td style="width:1.1rem;"></td>
                                <td style="width:1.1rem;"></td>
                                <td style="width:1.1rem;">
                                    {{ tableTotalData.total_weight }}
                                </td>
                                <td style="width:1.1rem;"></td>
                                <td style="width:1.1rem;">
                                    {{ tableTotalData.total_money }}
                                </td>
                            </tr>
                            <template v-for="(item, index) in tableData">
                                <tr :key="index">
                                    <td style="width:2rem;">
                                        {{ item.supplier_name }}
                                    </td>
                                    <td colspan="8">
                                        <table>
                                            <tbody>
                                                <template v-for="(k,z) in item.contracts">
                                                    <tr :key="z">
                                                        <td style="width:2rem;">
                                                            {{ k.contract_name }}
                                                        </td>
                                                        <td colspan="7" style="width: 7.7rem;">
                                                            <table>
                                                                <tbody>
                                                                    <template v-for="(j,x) in k.details">
                                                                        <tr :key="x">
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.station_name }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.type_name }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.child_type_name }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.spec_name }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.total_weight }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.station_average_price }}
                                                                            </td>
                                                                            <td style="width:1.1rem;">
                                                                                {{ j.total_money }}
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 物资采购合同-采购合同进度
    name: 'procurement-contract-schedule',
    components: {},
    props: {},
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '本日',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '昨日',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '本周',
                    onClick(picker) {
                        let end = '';
                        let start = '';

                        const myDate = new Date();
                        const day = myDate.getDay(); // 回退7天后是星期几？
                        const time = myDate.getDate() - day + (day === 0 ? -6 : 1);
                        const startTime = new Date(myDate.setDate(time));
                        start = startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
                        const endTime = new Date(myDate.setDate(time + 6));
                        end = endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();

                        picker.$emit('pick', [start, end]);

                    },
                }, {
                    text: '上周',
                    onClick(picker) {
                        let end = '';
                        let start = '';

                        const myDate = new Date(new Date() - 7 * 24 * 3600 * 1000);
                        const day = myDate.getDay(); // 回退7天后是星期几？

                        const time = myDate.getDate() - day + (day === 0 ? -6 : 1);
                        const startTime = new Date(myDate.setDate(time));
                        start = startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
                        const endTime = new Date(myDate.setDate(time + 6));
                        end = endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();

                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '本月',
                    onClick(picker) {
                        function getCurrentMonthLast() {
                            const date = new Date();
                            let currentMonth = date.getMonth();
                            const nextMonth = ++currentMonth;
                            const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
                            const oneDay = 1000 * 60 * 60 * 24;
                            return new Date(nextMonthFirstDay - oneDay);
                        }
                        function getCurrentMonthFirst() {
                            const date = new Date();
                            date.setDate(1);
                            return date;
                        }
                        const end = getCurrentMonthLast();
                        const start = getCurrentMonthFirst();
                        picker.$emit('pick', [start, end]);
                    },
                }],
            },
            // 查询条件
            queryCriteria: {
                // 供应商
                supplier_name: '',
                // 合同名称
                contract_name: '',
                // 场站
                station_id: '',
                // 时间段
                timeSlot: [],
                // 开始时间
                start_time: '',
                // 结束时间
                end_time: '',
            },
            // 场站数据
            stationData: [],
            // 列表数据
            tableData: [],
            // 列表合计数据
            tableTotalData: {},
            loading: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.loading = true;
        // 获取场站数据
        this.getStationData();
        // 获取列表数据
        this.getTableData();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 选择时间
        selectTime() {
            this.queryCriteria.start_time = this.queryCriteria.timeSlot ? this.queryCriteria.timeSlot[0] : '';
            this.queryCriteria.end_time = this.queryCriteria.timeSlot ? this.queryCriteria.timeSlot[1] : '';
        },
        // 获取场站数据
        getStationData() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId)
                .then(res => {
                    if (res) {
                        this.stationData.push(...res);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取列表数据
        getTableData() {
            this.$axios
                .post('/interfaceApi/report/procurementcontract/progress', this.queryCriteria)
                .then(res => {
                    if (res) {
                        this.tableTotalData = res.total;
                        this.tableData = res.suppliers;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 查询
        queryData() {
            if (this.queryCriteria.timeSlot) {
                this.queryCriteria.start_time = this.queryCriteria.timeSlot[0];
                this.queryCriteria.end_time = this.queryCriteria.timeSlot[1];
            } else {
                this.queryCriteria.start_time = '';
                this.queryCriteria.end_time = '';
            }
            // 获取列表数据
            this.getTableData();
        },
    },
};
</script>
<style lang="stylus" scoped>
.procurementContractSchedule
    height 100%
    padding 0.14rem
    background #fff !important
    .page_header
        height 0.5rem
        display flex
        .fonm_list
            display flex
            margin-right 0.2rem
            h4
                font-size 0.16rem
                line-height 0.36rem
                white-space nowrap
            .el-button
                height 0.36rem
                line-height 0.36rem
                padding 0
                width 0.6rem
            .el-date-editor
                height: 0.36rem
                line-height 0.36rem
    .currencyTable
        height calc(100% - 0.5rem)
        .el-table
            height 100%
            .table_content
                height calc(100% - 0.36rem)
                overflow-y auto
                >table
                    // width calc(100% - 9px)
                    width 100%
        table
            width 100%
            table-layout fixed
            border-collapse  collapse
            tr
                &:last-child
                    td
                        border-bottom none
                &.total
                    td
                        color #f60
                        background #fef9e4 !important
            th,td
                width 1rem
                height 0.36rem
                padding 0
            td
                border-bottom 1px solid #ebeef5
                &:last-child
                    border-right none
</style>
